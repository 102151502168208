.carousel-item {
  height: 100% !important;
}

.carousel-inner {
  height: 100%;
  min-height: 402px;
  display: flex;
  justify-content: center;
  align-items: center;
}
