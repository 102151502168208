.button {
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  padding: 0.65rem 1rem;
  border-radius: 6px;
  line-height: 18px;

  & i {
    font-size: 1rem;
    vertical-align: middle;
    line-height: 1;
    display: inline-flex;
  }

  &:disabled {
    opacity: 0.6;
  }

  &-primary-icon,
  &-secondary-icon,
  &-ghost-icon {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
  }

  &-primary,
  &-primary-icon {
    border: 1px solid var(--gks-blue);
    background-color: var(--gks-blue);
    color: white;

    > i {
      font-size: 1rem;
    }

    &:hover:enabled,
    &:focus:enabled {
      border: 1px solid var(--gks-navy-blue);
      background-color: var(--gks-navy-blue);
    }

    &:disabled {
      border: 1px solid var(--gks-blue);
      background-color: var(--gks-blue);
    }
  }

  &-secondary,
  &-secondary-icon {
    border: 1px solid var(--athens-gray-light);
    background-color: var(--athens-gray-light);

    &:hover:enabled,
    &:focus:enabled {
      border: 1px solid var(--athens-gray-light);
      background-color: var(--athens-gray-light);
    }

    &:disabled {
      border: 1px solid var(--athens-gray-light);
      background-color: var(--athens-gray-light);
    }
  }

  &-ghost,
  &-ghost-icon {
    border: 1px solid transparent;
    background-color: transparent;

    &:hover:enabled,
    &:focus:enabled {
      border: 1px solid var(--athens-gray);
      background-color: var(--athens-gray);
    }

    &:disabled {
      border: 1px solid transparent;
      background-color: transparent;
    }
  }

  &-primary-outlined {
    color: var(--gks-blue);
    border: 1px solid var(--gks-blue);
    background-color: white;

    > i {
      font-size: 1rem;
    }

    > i::before {
      color: var(--gks-blue);
    }

    &:hover:enabled,
    &:focus:enabled {
      border: 1px solid var(--gks-blue);
      background-color: var(--gks-blue);
      color: white;

      > i::before {
        color: white;
      }
    }

    &:disabled {
      color: var(--gks-blue);
      border: 1px solid var(--gks-blue);
    }
  }

  &-dark-link {
    border: 1px solid transparent;
    background-color: transparent;

    &:hover:enabled,
    &:focus:enabled {
      border: 1px solid var(--athens-gray);
      background-color: var(--athens-gray);
      color: var(--gks-blue);
    }

    &:disabled {
      border: 1px solid transparent;
      background-color: transparent;
    }
  }

  &-link {
    border: 1px solid transparent;
    background-color: transparent;
    color: var(--gks-blue);

    &:hover:enabled,
    &:focus:enabled {
      border: 1px solid var(--athens-gray);
      background-color: var(--athens-gray);
    }

    &:disabled {
      border: 1px solid transparent;
      background-color: transparent;
    }
  }

  &-outlined,
  &-outlined-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    padding: 0px;
    margin: 0px;

    border: 1px solid var(--athens-gray-light);
    background-color: var(--athens-gray-light);

    &:hover:enabled,
    &:focus:enabled {
      border: 1px solid var(--athens-gray);
      background-color: var(--athens-gray);
    }

    &:disabled {
      border: 1px solid transparent;
      background-color: transparent;
    }
  }

  &-secondary-outlined {
    border: 1px solid var(--athens-gray-light);
    background-color: white;

    > i {
      font-size: 1rem;
    }

    &:hover:enabled,
    &:focus:enabled {
      border: 1px solid var(--athens-gray-light);
      background-color: var(--athens-gray-light);
    }

    &:disabled {
      border: 1px solid var(--athens-gray-light);
    }
  }

  &-loader {
    vertical-align: -0.125em;
    margin-left: 3px;
  }
}
