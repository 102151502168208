.container {
  gap: 10px;
  position: relative;
}

.loader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.6;
  z-index: 10;
}
