$carousel-bg-color: #ffffff;

.journal-viewer {
  height: calc(100vh - 200px);
  max-height: 900px;
}

.carousel-inner {
  height: 100%;
}

.carousel-item {
  .carousel-item-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .default-inner-content {
    font-size: 13px;
    font-weight: 400;
  }

  .carousel-item-inner {
    background-color: $carousel-bg-color;
    max-width: 100%;
    height: 402px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: calc(100% - 100px);
    }
    &.empty {
      background: $carousel-bg-color;
    }
  }
  .slider-image {
    max-width: 80%;
    max-height: 390px;
    max-height: 402px;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 10% !important;
    background-color: $carousel-bg-color;
    opacity: 1 !important;
  }
}
