.map-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.map-drawing-controls {
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: absolute;
  bottom: 112px;
  right: 0px;

  i {
    color: rgba(#333333, 0.8);
  }
}
