.react-datepicker__header {
  background-color: var(--white);
  border: none;
}

.react-datepicker-time__caption {
  display: none !important;
}

.react-datepicker__input-time-container {
  display: flex;
  justify-content: center;
  margin: 15px 0 15px;
}

.react-datepicker__day--keyboard-selected {
  background-color: var(--gks-blue);
  font-weight: initial;
}

.react-datepicker__day--outside-month {
  color: var(--gks-blue);
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker-popper {
  z-index: 6;
}

.react-datepicker__input-container input {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.react-datepicker__close-icon::after {
  background-color: transparent !important;
  color: #b5b5c3 !important;
  font-size: 2rem;
  font-weight: 300;
}
