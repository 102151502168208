.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 38px;
}

.arrow-left {
  rotate: (-180deg);
}

.header-container {
  position: relative;
  cursor: pointer;
  max-height: 100%;
  width: max-content;
  overflow: hidden;
}
