* {
  box-sizing: border-box;
  padding: 0;
}

body {
  background-color: var(--white);
}

* ul {
  margin: 0;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.gm-style-iw-c button {
  top: 0px !important;
  right: 0px !important;
}

.dropdown-toggle:after {
  display: none !important;
  content: none !important;
}
