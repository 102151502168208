@mixin switch-view-breakpoint {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin show-hide-modifiers {
  &-show {
    display: flex;
  }

  &-hide {
    display: none;
  }
}

.content-map-layout__content-map__toggle {
  display: block;
  position: absolute;
  bottom: 8px;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);

  i {
    font-size: 1.8rem;
  }
}

@include switch-view-breakpoint {
  .content-map-layout__content-map__toggle {
    display: none;
  }
}

.content-map-layout__page__container {
  position: relative;
  flex: 1;
  width: 100%;
  overflow: auto;
  height: 100%;

  .content-map-layout__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .content-map-layout__content {
      display: flex;
      height: 100%;
      width: 100%;
      max-width: unset;
      overflow-y: auto;
      @include show-hide-modifiers();
    }

    .content-map-layout__map__content {
      display: none;
      height: 100%;
      width: 100%;
      margin-left: 0;
      max-width: unset;
      @include show-hide-modifiers();
    }

    @include switch-view-breakpoint {
      flex-direction: row;

      .content-map-layout__content {
        display: flex;
        @include show-hide-modifiers();
      }

      .content-map-layout__map__content {
        display: flex;
        margin-left: 1rem;
        @include show-hide-modifiers();
      }
    }
  }
}
