.user-name {
  font-size: 16px;
  color: var(--gks-navy-blue-dark);
}

.email,
.phone {
  font-size: 14px;
  color: var(--gks-grey-light);
  margin-top: 16px;
}

.separator {
  margin-top: 16px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--athens-gray-light);
}

.label,
.value {
  font-size: 13px;
  margin-top: 16px;
  color: var(--gks-navy-blue-dark);
}

.label {
  font-weight: bold;

  &:nth-child(1) {
    margin-top: 0;
  }
}

.sign-out-btn {
  margin-top: 24px;
  width: 90px;
}