.date-picker-wrapper {
  position: relative;
  padding-left: 2px;
  padding-right: 2px;

  & > div {
    width: 100%;
  }
}

.calendar {
  display: flex;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  &-day,
  &-week-day {
    width: 35px;
    // height: 35px;
    line-height: 35px;
    margin: 0;
    font-size: 1rem;
    margin: 2px;
  }
}

.preset-ranges {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 32px;
  margin-left: 8px;
  margin-right: 8px;
}

.date-picker-view {
  padding: 7.8px 7.8px 7.8px 0;
}
