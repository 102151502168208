$width: 190px;
$slider-position-offset: 34px;

.container {
  .switch {
    position: relative;
    display: inline-block;
    width: $width;
    height: 34px;

    input {
      display: none;
    }

    input:checked + .slider .on {
      display: block;
    }

    input:checked + .slider .off {
      display: none;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px var(--gks-blue);
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(calc(#{$width} - #{$slider-position-offset}));
      -ms-transform: translateX(calc(#{$width} - #{$slider-position-offset}));
      transform: translateX(calc(#{$width} - #{$slider-position-offset}));
      background-color: var(--gks-blue);
    }

    input:not(:checked) + .slider:before {
      background-color: white;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--athens-gray-light);
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 34px;

      .on {
        display: none;
        text-transform: uppercase;
      }
  
      .on,
      .off {
        color: black;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        font-size: 10px;
        font-family: Verdana, sans-serif;
      }
    }
  
    .slider:before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}
