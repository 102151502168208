.drawer {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  transition: transform 0.3s linear;

  &-open {
    width: 100%;
  }

  &-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--gks-navy-blue-dark);
    opacity: 0;
    transition: 0.3s linear;
    pointer-events: none;
  }

  &-mask-open {
    opacity: 0.4;
    transition: none;
    pointer-events: auto;
  }

  &-container-wrapper {
    height: 100vh;
    width: unquote('min(100vw, 466px)');
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    position: absolute;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }

  &-container-wrapper-open {
    right: 0px;
  }

  &-header {
    padding: 1rem 1rem 1rem 1.5rem;
    border-bottom: 1px solid var(--athens-gray-light);
  }

  &-body {
    overflow-y: auto;
  }
}
