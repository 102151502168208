.menu {
  position: fixed;
  visibility: hidden;
  display: none;
  z-index: 20;
}

.menu-visible {
  visibility: visible;
  display: initial;
}
