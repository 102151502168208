.close-btn {
  background-color: transparent;
  outline: none;
  width: unset;
  height: unset;
  border: 0;

  &:hover {
    background-color: transparent !important;
    border: 0 !important;
    > i {
      color: #008AE8;
    }
  }
}

.close-btn-icon {
  font-size: 2rem;
  background-color: #fff;
}
