.markdown-content-scope {
  * {
    all: initial;
    display: block;
    font-family: Poppins, Helvetica, 'sans-serif';
    font-weight: 500;
  }

  p,
  blockquote,
  ol,
  ul {
    margin: 1em 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    margin-bottom: 0.4rem;
    font-weight: 500;
    line-height: 1.2;
  }

  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.25em;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.83em;
  }
  h6 {
    font-size: 0.67em;
  }

  blockquote {
    padding-left: 10px;
    border-left: 4px solid #ccc;
  }

  code,
  pre {
    font-family: monospace;
  }

  pre {
    padding: 1em;
    overflow: auto;
    background-color: #f5f5f5;
  }

  ol,
  ul {
    padding-left: 40px;
    font-size: 14px;
    font-weight: 400;
    margin-top: 0;

    li {
      list-style: disc outside none;
      display: list-item;
      font-weight: 400;
      font-size: 13px;
    }
  }

  ol {
    li {
      list-style: decimal outside none;
    }
  }
}
