@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);

:root {
  /* GateKeeper colors defined in InvisionApp */
  --gks-navy-blue-dark: #00304e;
  --gks-navy-blue: #065796;
  --gks-blue: #0095eb;
  --gks-light-blue: #d2e9fd;
  --gks-indigo: #7f17e7;
  --gks-purple: #492a49;
  --gks-red: #ff553d;
  --gks-yellow: #ffcf00;
  --gks-green: #85b300;
  --gks-green-dark: #295135;
  --gks-grey: #cccccc;
  --gks-grey-light: #b5b5c3;
  --gks-brown: #9e6240;
  --athens-gray: #ebedf3;
  --athens-gray-light: #e4e6ef;
}

html {
  overscroll-behavior: none;
}

header {
  background-color: var(--gks-navy-blue-dark);
  color: var(--gks-navy-blue);

  flex-grow: 0;
}

main {
  flex-grow: 1;
  overflow-y: auto;
}

main * {
  scrollbar-width: thin;
}

.gm-style-iw > div {
  scrollbar-width: auto;
}

.gm-style-iw-chr,
.gm-style-iw-chr button {
  height: 20px;
}

main * ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 10px;
}

main > * ::-webkit-scrollbar-thumb {
  background: rgb(210, 210, 210);
  border-radius: 10px;
}

main > * ::-webkit-scrollbar-track {
  background: #f3f3f6c4;
  border-radius: 10px;
}

footer {
  background-color: #ffffff;
  bottom: 0;
  text-align: center;
  flex-grow: 0;
}
