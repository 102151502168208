.dual-checkboxes-list {
  &-title {
    font-size: 1rem;
    margin-bottom: 18px;
  }

  &-separator-container {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
  }

  &-separator-line {
    background-color: var(--athens-gray);
    width: 2px;
    height: 80%;
  }
}
