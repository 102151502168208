.container {
  display: flex;
  z-index: 1;
  position: absolute;
  top: 10px;
  left: 10px;
}

.action {
  color: black;
  margin-right: 5px;

  &-active {
    background-color: var(--gks-blue) !important;
    border-color: var(--gks-blue) !important;

    & > i {
      color: white !important;
    }
  }
}
