.container {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 1rem 1.5rem;
}

.faq-menu-option {
  background-color: #fff;

  &:hover {
    background-color: #fff !important;
  }
}
