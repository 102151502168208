.switch {
  width: max-content;

  &-disabled {
    opacity: 0.6;
  }
}

.subLabel {
  display: inline-block;
  margin-left: 0.5rem;
  margin-top: 0.4rem;

  &-view {
    height: 28px;
    margin-left: 0px;
    margin-top: 0px;
  }
}
