.card {
  cursor: pointer;
}

.card-header {
  min-height: initial !important;
  flex-wrap: nowrap !important;
}

.active-card {
  background-color: var(--athens-gray) !important;
}
