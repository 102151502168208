.uploader-file-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #eeeeee;
  border-radius: 6px;
  padding: 10px;
  height: 120px;
  width: 120px;
  margin-top: 20px;

  &-label:first-child {
    margin-top: 0px;
  }

  &-label {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 8px;
    margin-top: 18px;
    background-color: #ffffff;
    white-space: nowrap;
  }

  &-remove-btn {
    color: #7e8299;
    font-size: 1rem;
  }
}
