.textarea {
  resize: none;
  overflow-y: hidden;

  &-view {
    border-color: transparent !important;
    padding-left: 0 !important;
    background-color: transparent !important;
  }
}
