.list-item {
  padding: 10px;
  font-size: 1rem;
  border-bottom: 1px solid var(--athens-gray-light);

  &[aria-selected='true'] {
    background-color: var(--gks-blue);
    color: var(--gks-light-blue);
  }

  &-disabled {
    opacity: 0.8;
    background-color: var(--athens-gray);
  }
}
