.background-container {
  background-attachment: fixed;
  background-color: var(--athens-gray-light);
  background-image: url('/public/images/app-139-background.png');
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
}

.background-contents {
  background-color: var(--athens-gray);
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
