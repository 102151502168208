.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.header-no-changes {
  border-bottom-right-radius: calc(0.42rem - 1px) !important;
  border-bottom-left-radius: calc(0.42rem - 1px) !important;
}

.notification-link {
  color: black;
}

.notification-link:hover {
  color: inherit !important;
}

.no-radius-bottom-border {
  border-bottom: 0;
  border-top-left-radius: 10px;
}
