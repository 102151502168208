.calendar-header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;

  &-date {
    font-size: 1.1rem;
    font-weight: bold;
  }

  &-change-month-btn {
    padding: 10px;
    border: none;
    background-color: inherit;

    & > i {
      color: black;
      font-size: 1rem;
    }
  }
}
