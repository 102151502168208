.card-header {
  min-height: initial !important;
  flex-wrap: nowrap !important;
}

.highlight-card {
  background-color: #ffcc00 !important;
}

.active-card {
  background-color: var(--athens-gray) !important;
}

.actions-button {
  min-height: 38px;
  min-width: 38px;
}
