.page-size {
  display: flex;
  align-items: center;
  margin-left: 1rem;

  &-select-wrapper {
    max-width: 76px;
    min-width: 60px;
    width: 100%;
  }

  &-hint {
    color: var(--gks-grey-light);
    margin-left: 1rem;
    line-height: 1;
    // white-space: nowrap;
    text-align: end;
    // min-width: 190px;
  }
}
