.alert-overlay-container {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

.alert-overlay {
  bottom: 0px;
  position: absolute;
  width: 50%;
  z-index: 1100;
}

/*
    Overriding bootstrap variant colors to match the APP-139 style guide.
    https://coherentsolutions.invisionapp.com/console/APP-139-ckqn0o77aaa2701xej9ab0i1r/ckqr3lrmy061901698qen103p/play
*/
.alert-success {
  background-color: var(--gks-green) !important;
  border-color: var(--gks-green) !important;
  color: var(--white) !important;
}

.alert-danger {
  background-color: var(--gks-red) !important;
  border-color: var(--gks-red) !important;
  color: var(--white) !important;
}

.alert-primary {
  background-color: var(--gks-blue) !important;
  border-color: var(--gks-blue) !important;
  color: var(--white) !important;
}

.alert-warning {
  background-color: var(--gks-yellow) !important;
  border-color: var(--gks-yellow) !important;
  color: #000000 !important;
}
