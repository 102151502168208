.add-delete-button {
  height: 20px;
  width: 20px;

  & > i {
    color: var(--gks-gray);
    font-size: 2rem;
  }

  &:hover:enabled,
  &:focus:enabled {
    background-color: transparent !important;
    border: none !important;
  }
}

.subhead-row {
  background-color: var(--athens-gray);
}
