.transparent-background:hover,
.transparent-background:focus {
  background-color: transparent !important;
  border: 0px !important;
}

.action {
  width: 100%;
  border-radius: 0px;
  z-index: 20;
  text-align: left;
}

.list {
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: 220px;
  min-width: 120px;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  background-color: var(--white);
}
