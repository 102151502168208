.container {
  display: flex;

  & > div {
    margin-bottom: 0px;
  }

  &-horizontal {
    flex-direction: row;

    & > label:not(:first-child) {
      margin-left: 1rem;
    }
  }

  &-vertical {
    flex-direction: column;
  }
}
