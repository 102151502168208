.audit-info-container {
  display: flex;
  position: relative;
  padding-right: 40px;
  width: fit-content;
}

.audit-info-edit-btn {
  position: absolute;
  height: 20px;
  width: 20px;
  top: 0px;
  right: 0px;
  > i {
    color: black;
  }

  &:hover {
    background-color: initial !important;
    border: initial !important;
  }
}
