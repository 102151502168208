.checkbox-tree-container {
  height: 354px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--athens-gray-light);
  border-radius: 4px;
}

.no-nodes-preview {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  opacity: 0.4;
  color: #b5b5c3;

  & > i {
    font-size: 3rem;
  }
}
