.radio {
  position: relative;
  width: 18px;
  height: 18px;

  &-wrapper {
    display: block;

    &-disabled {
      opacity: 0.6;
      cursor: default;
    }
  }

  &-content {
    display: flex;
    align-items: center;
  }
}

.radio input {
  opacity: 0;
  width: 0;
  height: 0;
}

input {
  & + .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--athens-gray);
    transition: 0.2s;
    border-radius: 50%;

    &:before {
      position: absolute;
      content: '';
      background-color: var(--athens-gray-light);
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  &:checked {
    & + .slider {
      background-color: var(--gks-blue);
    }

    & + .slider:before {
      background-color: var(var(--athens-gray));
      top: 6px;
      left: 6px;
      height: 6px;
      width: 6px;
    }
  }
}

.sub-label {
  cursor: pointer;
  margin-left: 8px;
}
