.color {
  &-picker-wrapper {
    position: relative;

    &-disabled {
      opacity: 0.6;
    }
  }

  &-preview {
    height: 30px;
    width: 30px;
    right: 5px;
    top: 3px;
    position: absolute;
    border-radius: 4px;
    z-index: 3;
  }

  &-palette-wrapper {
    position: absolute;
    top: 40px;
    right: 4px;
    z-index: 4;
  }
}
