.document {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  background-color: #f3f6f9;
  border: 1px solid #f3f6f9;
  max-width: 452px;
  justify-content: space-between;

  &-uploading {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, #f3f6f9 8%, #bbbbbb 18%, #f3f6f9 33%);
    background-size: 452px;
    position: relative;
  }

  &-error {
    border-color: #f64e60;
  }
}

.document-name {
  padding: 0.5rem;
  padding-left: 1rem;
}

.document-size {
  font-weight: bold;
  padding: 0 0.5rem;
}

.document-action {
  border-radius: 0px;

  &-download {
    & > i::before {
      font-size: 1rem;
      font-weight: bold !important;
    }
  }

  &-re-upload {
    & > i::before {
      font-size: 1.2rem;
      font-weight: bold !important;
    }
  }

  &-remove {
    & > i::before {
      font-size: 1.5rem;
      font-weight: bold !important;
    }
  }
}

.document-error-message {
  color: #dc3545;
  margin-bottom: 0.5rem;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -452px 0;
  }
  100% {
    background-position: 452px 0;
  }
}
