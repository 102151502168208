.labels-column {
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    margin-right: 2px;
    &:not(:first-child) {
      margin-left: 8px;
    }
  }
}

.badge {
  display: inline-flex;
  align-items: center;

  --bs-success: #50cd89;
  --bs-success-light: #e8fff3;
  --bs-secondary: #dbdfe9;
  --bs-secondary-light: #f9f9f9;
  --bs-primary: #009ef7;
  --bs-primary-light: #f1faff;
  --bs-info: #7239ea;
  --bs-info-light: #f8f5ff;
  --bs-warning: #ffc700;
  --bs-warning-light: #fff8dd;
  --bs-danger: #f1416c;
  --bs-danger-light: #fff5f8;
  --bs-dark: #071437;
  --bs-dark-light: #f1f1f2;
}

.badge-light-success {
  color: var(--bs-success);
  background-color: var(--bs-success-light);
}

.badge-light-secondary {
  color: var(--bs-secondary);
  background-color: var(--bs-secondary-light);
}

.badge-light-primary {
  color: var(--bs-primary);
  background-color: var(--bs-primary-light);
}

.badge-light-info {
  color: var(--bs-info);
  background-color: var(--bs-info-light);
}

.badge-light-warning {
  color: var(--bs-warning);
  background-color: var(--bs-warning-light);
}

.badge-light-danger {
  color: var(--bs-danger);
  background-color: var(--bs-danger-light);
}

.badge-light-dark {
  color: var(--bs-dark);
  background-color: var(--bs-dark-light);
}
