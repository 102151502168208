.actions-button {
  position: absolute;
}

.actions-list {
  position: absolute;
  right: 0;
  margin-top: 5px;
  z-index: 6;
  max-width: 180px;
  box-shadow: 0 0 50px 0 var(--gks-grey-light);
  background-color: var(--white);
  display: flex;
  flex-direction: column;
}

.action {
  width: 100%;
  border-radius: 0px;
}
