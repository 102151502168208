.search-icon {
  position: absolute;
  left: 10px;
  top: 7px;
  z-index: 4;
}

.close-icon-button {
  position: absolute;
  right: 6px;
  top: 7px;
  z-index: 4;
  height: 24px;
  width: 24px;
  background-color: transparent !important;
  border: none !important;
}

.close-icon {
  font-size: 1rem;
}

.search-input-preview {
  padding-left: 32px !important;
  padding-right: 32px !important;
}
