.table-wrapper {
  overflow: auto;
  width: 100%;
  max-width: 100%;
  border: 4px solid #ebedf3;
  margin-bottom: 6px;
}

.table {
  width: 100%;

  thead {
    position: sticky !important;
    z-index: 9;
    background-color: white;
    top: 0;
  }

  tr {
    background-color: white;

    td,
    th {
      border-bottom: 2px solid #ebedf3;
    }

    &:last-child {
      td {
        border-bottom: 0px;
      }
    }
  }

  th,
  td {
    padding: 11px;
  }
}

.drag-icon-cell {
  position: sticky;
  left: 0px;
  background-color: white;
  z-index: 8;
  width: 40px !important;
  min-width: 40px !important;
  max-width: 40px !important;
}

th {
  &.checkbox-cell,
  &.drag-icon-cell {
    background-color: white;
    z-index: 7;
  }
}

.cell-string {
  width: 450px !important;
  max-width: 450px !important;
  min-width: 350px !important;
}

.checkbox-cell {
  width: 140px !important ;
  min-width: 140px !important;
  max-width: 140px !important;
}

.date-cell {
  width: 240px !important ;
  min-width: 240px !important;
  max-width: 240px !important;
}

.dropdown-cell {
  width: 240px !important ;
  min-width: 240px !important;
  max-width: 240px !important;
}

.actions-cell {
  position: sticky;
  right: 0px;
  background-color: white;
  z-index: 8;
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;
}

.row-actions-section {
  border: 1px solid #ebedf3;
  padding: 11px;
}

.subhead-cell {
  background-color: #ebedf3 !important;
}
