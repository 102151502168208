.actions-column {
  display: flex;
  justify-content: center;
  width: 70px;
  max-width: 70px;
  min-width: 70px;
}

.actions-button {
  margin: -1px;
  height: 20px;
  width: 20px;
}
