.filtered-dropdown-container {
  position: relative;
}

.filtered-dropdown-blanket {
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 1;
}

.filtered-dropdown-menu {
  background-color: #FFF;
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(218, 50%, 10%, 0.1), 0 4px 11px hsla(218, 50%, 10%, 0.1);
  height: 100px;
  min-width: 300px;
  position: absolute;
  z-index: 6;
}

.filtered-dropdown-group-label-container {
  display: flex;
  justify-content: center;
}

.filtered-dropdown-group-label {
  font-size: 14px;
  color: var(--gks-navy-blue);
}
