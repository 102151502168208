.checkbox {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  &-disabled {
    opacity: 0.7;
    cursor: auto;
  }

  &-input-wrapper {
    border: none;
    background-color: var(--athens-gray);
    border-radius: 4px;
    height: 18px;
    min-height: 18px;
    min-width: 18px;
    width: 18px;

    &-checked {
      background-color: var(--gks-blue);
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
    color: white;

    &::before {
      width: auto;
      height: auto;
      font-size: 1.25rem;
    }
  }

  &-input {
    height: 0px !important;
    width: 0px !important;
    margin: 0px;
    position: absolute;
    visibility: hidden;
  }

  &-label {
    margin-left: 5px;
  }
}
