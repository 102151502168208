.addon {
  &-before {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    margin-right: -1px;
  }

  &-after {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    margin-left: -1px;
  }
}
