.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.table-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  margin: 9px 0px 12px !important;
}

.query-input {
  width: 100%;
  max-width: 240px;
}

.table {
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin: 0px !important;
  border-radius: 4px;
  border: 4px solid var(--athens-gray);
}

.tr {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  &-selected {
    background-color: var(--athens-gray);
  }

  &-link {
    cursor: pointer;

    &:hover {
      background-color: var(--athens-gray);
    }
  }
}

.td,
.th {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0 !important;
  background-color: white;

  &-ellipsis {
    overflow: hidden;
  }

  &-content {
    padding: 0.75rem !important;

    &-ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.th {
  white-space: nowrap;
}

.actions-column {
  min-width: 80px;
}

.tr-loader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.6;
  z-index: 10;
}

.actions-column {
  display: flex;
  justify-content: center;
  width: 70px;
  max-width: 70px;
  min-width: 70px;
}

.actions-button {
  margin: -1px;
  height: 20px;
  width: 20px;
}
