.dual-list {
  &-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &-controll-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 20px;

    & > button {
      margin-bottom: 0.5rem;
    }
  }
}
