.menu {
  display: flex;
  list-style-type: none;

  &-horizontal {
    flex-direction: row;
    position: relative;
  }

  &-vertical {
    flex-direction: column;
  }

  &-horizontal {
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--secondary);
      position: absolute;
      bottom: 0;
      z-index: -1;
    }
  }
}
