.checklist-item {
  display: flex;
  align-items: center;
  border: solid 1px rgba(40, 40, 40, 0.05);
  background-color: rgba(40, 40, 40, 0.05);
  border-radius: 6px;
  border-style: solid;
  margin-bottom: 0.5rem;
  padding: 0 10px 0 15px;

  & > div {
    width: 100%;
  }
  & label {
    width: 100%;
  }
}

.checklist-item-description {
  padding: 15px;
  background-color: #0095eb0d;
  margin-bottom: 0.75rem;
}

.document-upload {
  max-width: 452px;
}

.eye-icon {
  &-active {
    color: var(--gks-blue);
  }
}
