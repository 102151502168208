.container {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.list {
  max-width: 300px;
  list-style-type: none;
}
