.uploader {
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 138px;
    border: 2px dashed #ebedf3;
    padding: 20px;

    &:hover {
      cursor: pointer;
    }

    &-active {
      border: 2px dashed blue;
    }
  }

  &-input {
    display: none;
  }

  &-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  &-title {
    font-size: 1.25rem;
    color: var(--dark);
    margin-bottom: 0.5rem;
  }

  &-subtitle {
    font-size: 1rem;
    color: var(--gks-grey-light);
    margin-bottom: 0;
  }
}
