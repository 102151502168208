.app-139-page {
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow-x: hidden;
}

.app-139-header {
  flex: 0 1 auto;
}

.app-139-main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}

.app-139-footer {
  flex: 0 1;
}

.app-139-content {
  margin: 0;
  padding: 0;
}

/* Adding the overflow-y-* rules here as the Bootstrap 4.6 support is underwhelming... https://getbootstrap.com/docs/4.6/utilities/overflow/ */
.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

