.react-select {
  min-height: auto !important;
  width: 100%;
  z-index: initial;
}

.react-select__control--is-disabled {
  background-color: var(--white) !important;
}

.react-select__menu {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  z-index: 5 !important;
}

.react-select__control {
  min-height: calc(1.5em + 1.3rem + 2px) !important;
  border-color: var(--athens-gray-light) !important;
  box-shadow: none !important;
  transition: border-color 0.15s ease-in-out !important;
  // margin-bottom: 8px;
}

.react-select__control:hover {
  cursor: pointer !important;
}

.react-select__control:focus-within {
  border-color: var(--gks-blue) !important;
}

.react-select__control:focus-within {
  border-color: var(--gks-blue) !important;
}

.react-select__indicators {
  height: 100%;
}

.react-select__indicator {
  padding: 6px 3px 3px !important;
}

.react-select__multi-value {
  color: white !important;
  background-color: var(--gks-blue) !important;
  min-width: 5rem !important;
}

.react-select__multi-value__label {
  color: white !important;
  padding-right: 0px;
  min-width: 3.5rem;
}

.react-select__multi-value__remove {
  color: white !important;
  padding-left: 2px !important;
  padding-right: 3px !important;
  margin-left: 2px;
}

.react-select-danger.react-select__control {
  border-color: var(--gks-red) !important;
}

.react-select__group {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.react-select__group-heading {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  background-color: var(--athens-gray-light);
  opacity: 0.7;
}
