.safety-item-spinner-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  min-width: 150px;
}

.safety-item-content,
.safety-concern-content {
  min-width: 150px;
}
