.info-container {
  position: relative;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: #fafafa;
  border: 1px solid var(--athens-gray);
}

.info-block-title {
  color: var(--gray);
  white-space: nowrap;
}

.info-block-value {
  margin-left: 0.5rem;
  color: var(--dark);
}
