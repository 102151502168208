.time-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 16px;

  &-toggle {
    background-color: inherit;
    padding: 5px;
    border: none;
  }

  &-icon {
    color: black;
  }
}
