// declare a variable in scss file
$footer-font-color: rgb(126, 126, 126);

.container {
  background-color: #ffffff;
  font-size: 0.85rem;
  color: $footer-font-color;
}

.link {
  color: $footer-font-color;
  text-decoration: underline;
}

.spacer {
  color: var(--gks-blue);
  margin-inline: 10px;
}