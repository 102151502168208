.menu-item {
  &-link {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: var(--gray);

    &-active {
      color: var(--blue);
      border-bottom: 1px solid var(--blue);
    }
  }

  &-link-horizontal {
    padding: 11px 16px;
  }

  &:not(:last-child) {
    .menu-item-link-horizontal {
      margin-right: 13px;
    }
  }

  &-link-vertical {
    padding: 9px 20px 9px;

    &-active {
      border-bottom: none;
    }
  }

  &-label {
    margin-left: 4px;
  }
}

.active {
  color: var(--gks-blue) !important;
}
