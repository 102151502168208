.mat-tab {
  font-family: Poppins, Helvetica, 'sans-serif' !important;
  font-size: 13px !important;
  font-weight: inherit !important;
  text-transform: none !important;
}

.horizontal-rule {
  margin-top: 0px !important;
}
