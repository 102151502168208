.checkbox-tree-node {
  &-container {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    position: relative;
  }

  &-dropdown {
    &-button {
      width: 15px;
      height: 15px;
      margin-right: 5px;
      position: absolute;
      left: 2px;
    }

    &-icon {
      width: 15px;
      font-size: 1rem;
    }
  }

  &-grip-icon {
    width: 15px;
    font-size: 1.25rem;
    transform: rotate(90deg);
    margin-right: 6px;
    height: 20px;
  }

  padding: 10px 20px;
}
