.navigation-menu {
  display: grid;
  align-items: center;
  grid-template-columns: calc(38px + 1rem) 1fr 44px 44px 44px 44px;
  grid-template-areas: 'logo airport-name faq-button notification-button profile-button hamburger-button';
  padding: 1rem;
}

.hamburger-button {
  grid-area: hamburger-button;
}

.navigation-list {
  grid-area: navigation-list;
  display: none !important;
}

.faq-button {
  grid-area: faq-button;
}

.notification-button {
  grid-area: notification-button;
}

.profile-button {
  grid-area: profile-button;
}

.logo {
  grid-area: logo;
  margin-right: 1rem;

  & > img {
    height: 38px;
    width: 38px;
  }
}

.separator {
  grid-area: separator;
  font-size: 1.5rem;
  color: white;
  margin: 0px 1rem;
  display: none;
}

.airport-name {
  grid-area: airport-name;
  font-size: 1.5rem;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 1199px) {
  .navigation-menu {
    display: grid;
    grid-template-columns: calc(45px + 1.5rem) 1fr auto 20px 44px 44px 44px;
    grid-template-areas: 'logo navigation-list airport-name separator faq-button notification-button profile-button';
    padding: 1rem;
  }

  .hamburger-button {
    display: none;
  }

  .navigation-list {
    display: initial !important;
  }

  .navigation-list-wrapper {
    display: flex;
    flex-direction: row !important;

    > li {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }

  .separator {
    display: initial;
  }

  .airport-name {
    font-size: unset;
  }

  .logo {
    margin-right: 1.5rem;

    & > img {
      height: 45px;
      width: 45px;
    }
  }
}
