.container {
  display: flex;
  margin: 0 -4px;
}

.pagination {
  &-btn {
    margin: 0 2px;
    padding: 0px;
    height: 32px;
    width: 32px;
  }

  &-icon {
    color: var(--gks-navy-blue-dark);
    font-size: 1rem;
  }
}
