.wrapper {
  margin-bottom: 0px;
}

.field-text {
  margin-top: 8px;

  &-before {
    margin-right: 7px;
  }
  &-after {
    margin-left: 7px;
  }
  &-both {
    margin-right: 7px;
    margin-left: 7px;
  }
}
