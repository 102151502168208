.attributes-row {
  border: solid 1px rgba(40, 40, 40, 0.05);
  background-color: rgba(40, 40, 40, 0.05);
  display: flex;
  gap: 20px;
  cursor: pointer;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.attributes-drag-icon {
  display: flex;
  align-items: center;
}

.attributes-delete-btn {
  min-width: 38px;
  max-width: 38px;
}

.no-data-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  font-size: 1.5rem;
  opacity: 0.4;
  color: #b5b5c3;

  & > i {
    font-size: 3rem;
  }
}
