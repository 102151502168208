.navbar-icon-actions-button {
  height: 38px;
  width: 38px;
  margin-left: 0.5rem;

  & > i {
    color: #ffffff;
    font-size: 1.5rem;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    border-color: transparent !important;
    background-color: var(--gks-light-blue) !important;

    & > i {
      color: var(--gks-blue);
    }
  }
}
