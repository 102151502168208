.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 300px;
}

.filter {
  margin-bottom: 10px !important;
}

.list {
  list-style-type: none;

  &-wrapper {
    min-height: 354px;
    max-height: 354px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border: 1px solid var(--athens-gray-light);
    border-radius: 4px;
  }

  &-title {
    padding: 10px;
    font-size: 1rem;
    border-bottom: 1px solid var(--athens-gray-light);
    font-weight: bold;
  }
}
