.alert-bar {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  text-align: center;
  border-radius: 0 !important;
  border: none !important;
  margin-bottom: 0;
}

.alert-content {
  display: flex;
  justify-content: center;
  width: 100%;
}

.alert-close-btn {
  height: 20px;
  width: 20px;

  & > i {
    color: white;
  }

  &:hover:enabled,
  &:focus:enabled {
    background-color: transparent !important;
    border: none !important;
  }
}
